#contact-holder {
  background: $brand-secondary;
  padding-top: 3rem;
  padding-bottom: 3rem;
  .media {
    color: $brand-white; }
  .media-object img {
    width: 100%;
    border-radius: 2rem; }
  .media-heading,
  a {
    color: $brand-white; }
  .media-left {
    padding-right: 4rem; } }
