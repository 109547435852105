/*
 *
 * X-Pro II
 *
 */
@import 'shared';

%xpro2,
.xpro2 {
  @extend %filter-base;
  filter: sepia(.3);

  &:after{
    background: radial-gradient(circle, #E6E7E0 40%, rgba(43, 42, 161, .6) 110%);
    mix-blend-mode: color-burn;
  }
}
