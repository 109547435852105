$carousel-height: 32rem;
%spacer-y-nav {
  margin-top: 1rem;
  padding-top: 4rem; }
.logo-hero {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  text-align: center;
  svg.railequip-logo {
    display: inline-block;
    height: 70px;
    margin-top: 1.5rem; } }

.section-header {
  text-transform: uppercase;
  font-weight: 700;
  text-align: left;
  color: $brand-primary;
  font-size: 1.2em; }

@import "modules/gallery";

#products-holder {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: $brand-secondary;
  box-shadow: inset 0 0 .5rem darken($brand-terciary, 10);
  hr {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .description {
    padding-bottom: 2rem; }
  .product-item {
    text-align: center;
    display: block;
    color: $brand-white;
    .logo {
      border-radius: 50%;
      width: 75%;
      height: auto;
      margin: .2rem auto 1rem;
      box-shadow: 0 0 1rem darken($brand-terciary, 10); }
    .title {
      font-weight: 300;
      color: $white; }
    .description {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-bottom: 0; }
    &:hover {
      text-decoration: none;
      text-shadow: 0 0 .5rem $brand-primary;
      .logo {
        box-shadow: 0 0 1rem $brand-primary; } } } }
#services {
  .section-header {
    margin-bottom: 5rem; }
  @import "modules/featurettes"; }

@import "modules/_contact";

#products,
#services,
#contact,
#about {
  @extend %spacer-y-nav; }
