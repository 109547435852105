.main-gallery {
  padding-top: 2rem;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }
.gallery-cell {
  height: $carousel-height;
  margin-right: 2rem;
  border-radius: $border-radius-lg;
  overflow: hidden;
  position: relative;
  // background: radial-gradient( circle, rgba(black, .8),rgba(black, .0) 55% )
  width: 60%;
  opacity: .7;
  filter: blur(4px);
  transition: opacity 1.5s, filter 2s;
  &.is-selected {
    opacity: 1;
    transform: scale(1);
    filter: none; }
  .caption {
    text-align: center;
    position: absolute;
    top: 5.5rem;
    right: 14%;
    left: 14%;
    z-index: 2;
    font-weight: 300;
    font-size: 1.4rem;
    text-shadow: 0px 1px 2px rgba($brand-terciary, .6), 0px -1px 2px rgba($brand-terciary, .6);
    color: $brand-white;
    line-height: 1.3em;
    letter-spacing: 0.05em;
    img {
      border-radius: 50%;
      width: 16rem;
      height: auto;
      margin: 0 auto .5rem; } } }

.flickity-page-dots {
  bottom: .7rem; }

.flickity-page-dots .dot {
  transform: rotate(45deg);
  height: 1rem;
  width: 1rem;
  margin: 0 .5rem;
  border-radius: .2rem;
  background: $brand-secondary;
  opacity: .6;
  &:hover {
    background: $brand-secondary;
    opacity: .9; }
  &.is-selected {
    background: $brand-primary; } }

.flickity-prev-next-button {
  width: 100px;
  height: 100px;
  background: transparent; }

.flickity-prev-next-button:hover {
  background: transparent; }

.flickity-prev-next-button .arrow {
  fill: rgba($brand-primary, .6); }

.flickity-prev-next-button.no-svg {
  color: rgba($brand-primary, .6); }

.flickity-prev-next-button:disabled {
  display: none; }
