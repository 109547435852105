.company-logo {
  width: 50%;
  margin: 0.25rem auto 2.5rem;
  color: $brand-white; }
.company-jumbotron {
  background-attachment: initial; }
#customers,
#sectors,
#certificates {
  padding-bottom: 2rem; }
