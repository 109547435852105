.jumbotron {
  border-radius: 0;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-top: 3em;
  margin-bottom: 0;
  text-align: center;
  background-color: $brand-secondary;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;

  .container {
    max-width: 50rem; }
  .brand-logo {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 18rem;
    height: 18rem;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 1rem;
    &.bg-shadow {
      box-shadow: 0 0 1rem darken($brand-terciary, 10); } }

  .jumbotron-heading {
    font-weight: 300;
    color: $white;
    text-shadow: 0 0 .25rem $brand-terciary;
    margin-bottom: 0; }
  p {
    color: $white;
    margin-bottom: 0;
    font-weight: 700;
    text-shadow: 0 0 .5rem $brand-terciary;
    a {
      color: lighten($brand-secondary, 20); } } }
.brand-description {
  font-weight: 400;
  margin: 1.5em auto;
  color: $brand-primary;
  max-width: 62rem;
  ul {
    padding-left: 0;
    border-bottom: 1px solid rgba($brand-primary,.3);
    margin: 2em 0; }
  li {
    list-style: none;
    margin: 0;
    padding: 0.3em;
    border-top: 1px solid rgba($brand-primary,.3); }
  a {
    display: inline-block;
    border-bottom: 1px solid rgba($brand-primary,.3);
    &:hover {
      text-decoration: none;
      border-color: rgba($brand-secondary,.3); } } }
.list {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 0; }

.list-item {
  display: flex;
  padding: 0 0.4rem;
  width: 100%;
  margin-bottom: .75rem;
  color: $body-color;
  &:hover {
    text-decoration: none;
    .card {
      border-color: $brand-primary; }
    .btn-more {
      @include button-variant($brand-white, $brand-primary, $brand-primary); } }
  @include respond-to(lg) {
    width: 50%; }
  @include respond-to(md) {
    width: 50%; }
  @include respond-to(sm) {
    width: 50%; }
  @include respond-to(xl) {
    width: 33.333%; } }

.list-content {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 1em;
  width: 100%;
  p {
    flex: 1 0 auto; } }
.card {
  position: relative;
  border-color: $brand-white;
  border-radius: 0;
  margin-bottom: 0; }
.card-block {
  margin-bottom: 2rem; }
.card > img {
  margin-bottom: .75rem; }

.card-text {
  font-size: 85%; }
.btn-more {
  position: absolute;
  bottom: $card-spacer-y;
  right: $card-spacer-y + $card-spacer-x;
  left: $card-spacer-y + $card-spacer-x;
  @include button-variant($brand-white, $brand-secondary, $brand-terciary); }


