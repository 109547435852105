svg {
  fill: currentColor; }
.logo-hero svg.railequip-logo {
  .railequip-monotip,
  .monotip-part {
    fill: $brand-primary; }
  .railequip-letters,
  .letter {
    fill: $brand-white;
    stroke: $brand-primary;
    stroke-width : .2px; } }

.navbar-brand svg.railequip-logo {
  .railequip-monotip,
  .monotip-part {
    fill: $brand-white; }
  .railequip-letters,
  .letter {
    fill: $brand-white; } }
