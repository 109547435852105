body {
  position: relative; }
.text-center {
  text-align: center; }
.text-right {
  text-align: right; }
footer {
  padding-top: 2rem;
  padding-bottom: 2rem; }
.navbar-lang {
  float: right;
  .nav-link {
    color: $brand-terciary;
    text-shadow: 0 0 .1rem $brand-white;
    &:hover {
      color: $brand-primary; } }
  .active .nav-link {
    font-weight: bold;
    text-shadow: 0 0 .1rem $white;
    &:hover {
      cursor: default;
      color: $brand-terciary; } } }

.cssfilter {
  @extend %xpro2; }
