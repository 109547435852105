$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);


@mixin respond-to($media) {
  @if $media == sm {
    @media only screen and (min-width: map-get($grid-breakpoints, sm)) { @content; }
  }
  /*
  */
  @else if $media == md {
    @media only screen and (min-width: map-get($grid-breakpoints, md)) { @content; }
  }
  @else if $media == lg {
    @media only screen and (min-width: map-get($grid-breakpoints, lg)) { @content; }
  }
  @else if $media == xl {
    @media only screen and (min-width: map-get($grid-breakpoints, xl)) { @content; }
  }
}

%sneak {
  display: block;
  // display: none;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  color: white;
  width: 100% ;
  font-size: .5rem;
  background: black;
  &:before{
    display: block;
    padding: .3em;
  }
  @include respond-to(sm) {
    color: green;
    &:before{content:"[sm] phone";}
  }
  @include respond-to(md) {
    color: orange;
    &:before{content:"[md] tablet";}
  }
  @include respond-to(lg) {
    color: red;
    &:before{content:"[lg] laptop";}
  }
  @include respond-to(xl) {
    color: pink;
    &:before{content:"[xl] desktop";}
  }
}
.sneak{
  @extend %sneak
}
