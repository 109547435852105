// Landing Featurettes
.featurette-divider {
  margin: 5rem 0;
  border-color: lighten($brand-secondary, 40%);
  &:last-child {
    border-color: transparent;
    margin-bottom: 0; } }

.media-heading {
  font-weight: 300;
  line-height: 1;
  margin-bottom: 1.3rem;
  letter-spacing: -.05rem;
  color: $brand-primary; }

.media-content p:first-child {
  @extend .lead;
  color: $brand-terciary;
  margin-bottom: 1.3rem; }

.media-object {
  @extend .cssfilter;
  border-radius: $border-radius-lg;
  overflow: hidden;
  margin-bottom: 0;
  img {
    width: 100%; } }
.media-right,
.media-left {
  width: 45%; }
.media-right {
  padding-left: 4rem; }
.media-left {
  padding-right: 4rem; }

// RESPONSIVE CSS
@media (min-width: 40em) {
  .media-heading {
    font-size: 50px; } }

