.navbar-railequip {
  background: $brand-terciary;
  border-radius: 0; }
.navbar-brand {
  overflow: hidden;
  position: relative;
  overflow: visible;
  height: 2rem;
  width: 12rem;
  display: block;
  svg {
    top: 0;
    left: 0;
    position: absolute; } }
.navbar-brand-sm {
  @extend .hidden-md-up;
  width: 2rem; }

.navbar-text {
  margin-bottom: 0;
  padding: 0.425rem 0;
  float: right !important;
  color: $navbar-dark-active-color; }
