// MY COLORS
$brand-primary: #8D181B;
$brand-secondary: #5A504A;
$brand-terciary: #4E443C;
$brand-white: #EFEBE3;
$white: lighten($brand-white,10);

// BS

$font-family-sans-serif:     'Source Sans Pro', "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:          'Source Serif Pro', Georgia, "Times New Roman", Times, serif;
$font-family-monospace:      'Source Code Pro',Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:           $font-family-sans-serif;

$gray-dark:                 #373a3c;
$gray:                      #55595c;
$gray-light:                #818a91;
$gray-lighter:              #eceeef;
$gray-lightest:             #f7f7f9;

$brand-primary:             $brand-primary;
$brand-success:             #5cb85c;
$brand-info:                #5bc0de;
$brand-warning:             #f0ad4e;
$brand-danger:              #d9534f;


$body-bg:                    $brand-white;
$body-color:                 #5F574F;


// Links
$link-color:                 $brand-primary; // #08C
$link-hover-color:           darken($link-color, 15%);
$link-hover-decoration:      underline;

$card-border-color:          #C09853;

$enable-flex:               true;

$border-radius:          0; // !default;
$border-radius-lg:       0;
$border-radius-sm:       0; // !default;
