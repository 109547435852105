.product-details {
  padding-top: 2rem;
  .content {
    padding-bottom: 2rem;
    font-size: 1.2rem;
    h2 {
      font-weight: 100;
      background-color: rgba($brand-secondary,.08);
      padding: .1em;
      margin-left: -.2em; }
    ul {
      padding-left: 0;
      margin-left: 0;
      border-bottom: 1px solid rgba($brand-primary,.3);
      margin-bottom: 2em; }
    li {
      list-style: none;
      margin: 0;
      padding: 0.3em;
      border-top: 1px solid rgba($brand-primary,.3); }
    img {
      @extend .img-fluid; } } }

.pagination {
  li {
    > a {
      color: $brand-terciary;
      background-color: transparent;
      border: $pagination-border-width solid $brand-secondary;
      border-width: 2px 0 2px 1px;
      border-radius: 0 !important;
      margin-bottom: 1rem;
      &.brand {
        border-left: 0;
        //background-color: $brand-white
 }        //color: $brand-secondary
      &:hover {
        background-color: $brand-primary;
        border-color: $brand-primary;
        color: $brand-white;
        text-decoration: none; } } } }

.pagination-product {
  display: block; }
